const Vue = require('vue')

module.exports = function () {
	if (window.celcius && window.celcius.bpost && document.querySelector('#vue-service')) {
		new Vue({
			el: '#vue-service',
			data: {
				bpost: window.celcius.bpost,
				search: null,
				city: null,
				isWestVlaanderen: true
			},
			methods: {
				lookup: function () {
					let needle = this.bpost[this.search]

					if (needle) {
						this.city = needle
						this.isWestVlaanderen = (this.search >= 8000 && this.search <= 8980)
					} else {
						this.city = null
					}
				}
			}
		})
	}
}
