const Vue = require('vue')

module.exports = function () {
	if (document.querySelector('#vue-faq')) {
		new Vue({
			el: '#vue-faq',
			data: {
				id: null
			},
			methods: {
				toggleFaq: function (id) {
					this.id = (this.id === id) ? null : id
				}
			}
		})
	}
}
